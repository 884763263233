<template>
  <div class="recharging-state">
    <el-row v-for="(row, index) of rechargingStateData.series" :key="index" type="flex"
      class="align-items-center"
      style="padding-left:1rem;"
    >
      <div class="row-name" :style="{color:filterColorStyle(row.name)}">{{row.name}}</div>
      <RechargingStateItem
        :size="childSize"
        class="recharging-state-item"
        v-for="(item, order) of row.data"
        :key="order"
        :stateInfo="item | filterRechargingInfo(row)"
      ></RechargingStateItem>
    </el-row>
    <el-row
      type="flex"
      style="padding-left:4rem;"
    >
       <div class="xserie-item" :style="{width:`${childSize.width/2*3}px`}"
        v-for="(xSerie, index) of rechargingStateData.xSeries"
        v-show="index%3==0"
        :key="index">{{xSerie}}</div>
    </el-row>
  </div>
</template>
<script>
import RechargingStateItem from '@/components/project-statistic-analysis/recharging-state-item.vue';
import YellowCarPng from '@/assets/images/project/statistic-analysis/yellow-car.png';
import GreenCarPng from '@/assets/images/project/statistic-analysis/green-car.png';
import RedCarPng from '@/assets/images/project/statistic-analysis/red-car.png';

export default {
  name: 'recharging-state',
  props: {
    stateInfo: {
      type: Object,
      default() {
        return null;
      },
    },
    size: {
      type: Object,
      default() {
        return {
          width: 0,
          height: 0,
        };
      },
    },
  },
  components: { RechargingStateItem },
  data() {
    return {

    };
  },
  computed: {
    rechargingStateData() {
      if (!this.stateInfo) {
        return null;
      }
      return {
        xSeries: this.stateInfo.xAxisData,
        series: this.stateInfo.ChongDianXianLuFenXi.map((item) => ({
          name: item.GroupName,
          type: 'line',
          smooth: true,
          data: item.List.map((info) => parseInt(info.Value, 10)),
        })),
      };
    },
    childSize() {
      return {
        width: this.size.width / 24,
        height: this.size.height / 3,
      };
    },
  },
  filters: {
    filterRechargingInfo(info, row) {
      let bgColor = '';
      let carImg = null;
      switch (row.name) {
        case 'A':
          bgColor = '#FFFF01';
          carImg = YellowCarPng;
          break;
        case 'B':
          bgColor = '#008000';
          carImg = GreenCarPng;
          break;
        case 'C':
          bgColor = '#FE0000';
          carImg = RedCarPng;
          break;
        default:
          break;
      }
      let isCanRecharg = false;
      // eslint-disable-next-line eqeqeq
      if (info === 0) {
        isCanRecharg = true;
      }
      return {
        bgColor,
        // canRecharg: !!info,
        canRecharg: isCanRecharg,
        carImg,
      };
    },
  },
  methods: {
    filterColorStyle(name) {
      switch (name) {
        case 'A':
          return '#FFFF01';
        case 'B':
          return '#008000';
        case 'C':
          return '#FE0000';
        default:
          return '';
      }
    },
  },
};
</script>
<style scoped>
.row-name{
  width: 50px;
}
.xserie-item{
 text-align:left;
}
.recharging-state-item{
  position: relative;
}
.recharging-state-item+.recharging-state-item:before{
  position: absolute;
  content: " ";
  width:1px;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #25c1ff;
}
</style>
