import { request } from '@/utils/request/request';
// 获取可开放容量
export function getOpenCapacity(params) {
  return request({
    url: 'api/tongji/v1/GetKeKaiFangRongLiang',
    method: 'GET',
    params,
  });
}
// 获取变压器损耗
// export function getTransLoss(params) {
//   return request({
//     url: 'api/tongji/v1/GetBianYaQiSunHao',
//     method: 'GET',
//     params,
//   });
// }
export function getTransLoss(params) {
  return request({
    url: 'api/tongji/v1/GetMuXianBuPingHeng',
    method: 'GET',
    params,
  });
}

// 负荷预测
export function getLoadForcasting(params) {
  return request({
    url: 'api/tongji/v1/GetFuHeYuCeFenXi',
    method: 'GET',
    params,
  });
}
// 充电桩分析
export function getRechargeAnalysis(params) {
  return request({
    url: 'api/APPAPI_NEW/v1/GetChongDianXianLuFenXi',
    method: 'GET',
    params,
  });
}
// 获取充电桩列表
export function getRechargeList(params) {
  return request({
    url: 'api/APPAPI_NEW/v1/GetChargeEquipmentIDList',
    method: 'GET',
    params,
  });
}
