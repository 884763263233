<template>
  <div
    class="
      project-statistic-analysis
      flex-grow-1
      d-flex
      flex-column
      postion-relative
    "
  >
    <Backup class="align-self-start"></Backup>
    <div class="none-height flex-grow-1 d-flex flex-column">
      <el-row
        class="row-container none-height flex-grow-1"
        type="flex"
        :gutter="12"
      >
        <el-col :span="12" class="flex-grow-1 d-flex flex-column">
          <SectionFrame class="row-container none-height flex-grow-1"
          style="background: linear-gradient(
          15deg, #1c4596, #181d62);">
            <div class="align-self-start">可开放容量</div>
            <el-table size="mini" stripe
            :data="openCapacityData"
            height="100%"
            class="dark flex-grow-1 "
              :row-class-name="
            (...nu) => {
              return  'dark text-white';}
          ">
              <el-table-column type="index" label="#"></el-table-column>
              <el-table-column label="名称" prop="EquipmentName"></el-table-column>
              <el-table-column label="容量(kVA)" prop="EDingRongLiang"></el-table-column>
              <el-table-column label="最高负荷(kW)" prop="ZuiGaoFuHe"> </el-table-column>
              <el-table-column label="可开放容量(kVA)" prop="KeKaiFangRongLiang"> </el-table-column>
            </el-table>
          </SectionFrame>
          <SectionFrame class="row-container none-height flex-grow-1">
            <div class="align-self-start">损耗分析</div>
            <el-table
              :data="tranLossData"
              stripe
              height="0"
              class="dark flex-grow-1"
              size="mini"
              :row-class-name="
                (...nu) => {
                  return  'dark text-white';}
              "
            >
              <el-table-column label="#" type="index"> </el-table-column>
              <el-table-column label="名称" prop="SBName"></el-table-column>
              <el-table-column label="进电量(kWh)" prop="ShuRuDianLiang"></el-table-column>
              <el-table-column label="出电量(kWh)" prop="ShuChuDianLiang"></el-table-column>
              <el-table-column label="损耗电量(kWh)" prop="BuPingHengDianLiang"></el-table-column>
              <el-table-column label="损耗(%)" prop="BuPingHengLv"></el-table-column>
            </el-table>
          </SectionFrame>
        </el-col>
        <el-col :span="12" class="d-flex flex-column">
          <SectionFrame class="none-height flex-grow-1">
            <div class="align-self-start">负荷预测分析</div>
            <Charts :options="tendencyData" class="flex-grow-1"></Charts>
          </SectionFrame>
        </el-col>
      </el-row>
      <SectionFrame
        class="row-container none-height flex-grow-1 d-flex flex-column position-relative"
      >
        <div
          class="
            select-option-container
            position-absolute
            d-flex
            justify-content-center
          "
          style="width: 100%; top: 1rem"
        >
          <select-options-nav
            @selectOption="getSelectOption"
            :selectOption="selectOrder"
            :selectList="rechargeShowList"
          ></select-options-nav>
        </div>
        <div class="align-self-start">电动汽车充电线路分析</div>
        <template v-if="rechargeData">
          <Charts ref="rechargingChart" class="flex-grow-1" :options="imbalanceChartData"></Charts>
          <RechargingState
            :size="rechargingSize"
            :stateInfo="rechargeData"
            class="position-absolute recharging-state flex-grow-1"
          ></RechargingState>
        </template>
        <NoDataTemporarily v-else></NoDataTemporarily>
      </SectionFrame>
    </div>
  </div>
</template>
<script>
// 统计分析
import ProjectMixin from '@/mixins/project-mixin';
import Charts from '@/components/general/chart.vue';
import RechargingState from '@/components/project-statistic-analysis/recharging-state.vue';
import selectOptionsNav from '@/components/general/select-options-nav.vue';
import { addResizeListener, formatDate } from '@/utils/utils';
import {
  getOpenCapacity,
  getTransLoss,
  getLoadForcasting,
  getRechargeAnalysis,
  getRechargeList,
} from '@/api/project/statistic-analysis';

export default {
  name: 'project-statustic-analysis',
  mixins: [ProjectMixin],
  components: { Charts, RechargingState, selectOptionsNav },
  data() {
    return {
      rechargeData: null,
      openCapacityData: null,
      tranLossData: null,
      loadForcastingData: null,
      selectOptionList: ['地下1#充电桩', '地下2#充电桩', '地上充电桩'],
      selectOrder: '',
      rechargeList: [],
      titleList: [
        {
          title: '实时数据',
          value: 0,
        },
        {
          title: '线损查询',
          value: 1,
        },
      ],
      rechargingSize: {
        width: 0,
        height: 60,
      },
      IsRegisteRechargingChartResizeListener: false,
    };
  },
  created() {
    this.requestOpenCapacity();
    this.requestTranLoss();
    this.requestLoadForcasting();
    this.requestRechargeList();
  },
  // mounted() {
  //   this.getRechargingBoxSize();
  //   console.log('this.selectProject', this.selectProject);
  //   addResizeListener(this.$refs.rechargingChart.$el, this.getRechargingBoxSize);
  // },
  mounted() {
  },
  updated() {
    if (this.$refs.rechargingChart && !this.IsRegisteRechargingChartResizeListener) {
      this.getRechargingBoxSize();
      console.log('this.selectProject', this.selectProject);
      addResizeListener(this.$refs.rechargingChart.$el, this.getRechargingBoxSize);
      this.IsRegisteRechargingChartResizeListener = true;
    }
  },
  computed: {
    rechargeShowList() {
      return this.rechargeList.map((recharge) => ({
        IdStr: recharge.int_SBID,
        ModelName: recharge.str_SBName,
        oType: '',
      }));
    },
    imbalanceChartData() {
      if (!this.rechargeData) {
        return null;
      }
      return {
        grid: {
          left: '3%',
          right: '3%',
          bottom: '45',
          containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999',
            },
          },
        },
        legend: {
          data: [
            { name: 'A', icon: 'circle', textStyle: { color: 'auto' } },
            { name: 'B', icon: 'circle', textStyle: { color: 'auto' } },
            { name: 'C', icon: 'circle', textStyle: { color: 'auto' } },
            {
              name: '三相不平衡率',
              icon: 'circle',
              textStyle: { color: 'auto' },
            },
          ],
        },
        xAxis: [
          {
            type: 'category',
            show: false,
            data: this.rechargeData.xAxisData,
            axisPointer: {
              type: 'shadow',
            },
            axisLine: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            name: 'A',
            type: 'value',
            splitLine: {
              show: true,
              lineStyle: { color: 'gray', type: 'dashed' },
            },
            axisLine: {
              show: false,
              lineStyle: { color: '#63b8d5', type: 'dashed' },
            },
            position: 'left',
            axisLabel: { formatter: '{value} ' },
          },
          {
            show: true,
            name: '%',
            type: 'value',
            boundaryGap: ['20%', '20%'],
            splitLine: {
              show: true,
              lineStyle: { color: 'gray', type: 'dashed' },
            },
            axisLine: {
              // show: true,
              lineStyle: { color: '#63b8d5', type: 'dashed' },
              formatter: '{value} %',
            },
            // position: 'right',
            // axisLabel: {
            //   show: true,
            //   // formatter: '{value} %',
            // },
          },
        ],
        series:
        this.rechargeData.seriesData.map((item) => ({
          name: item.GroupName,
          type: 'line',
          yAxisIndex: item.GroupID === 'SXBPHL' ? 1 : 0,
          smooth: true,
          data: item.List.map((info) => info.Value),
        })),
      };
    },

    selectProject() {
      return this.$store.getters.selectProject;
    },
    tendencyData() {
      if (this.loadForcastingData == null) {
        return null;
      }
      return {
        tooltip: { trigger: 'axis' },
        legend: {
          data: ['当前负荷', '预测负荷'],
          textStyle: { fontSize: '14', color: 'auto' },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: this.loadForcastingData.xAxisData,
            axisLine: { color: '#fff' },
            axisLabel: { color: '#fff' },
          },
        ],
        yAxis: [
          {
            type: 'value',
            name: 'kW',
            // axisLine: { color: '#fff' },
            axisLine: {
              lineStyle: {
                color: '#fff', width: 1, // 这里是为了突出显示加上的                        },
              },
            },
            axisLabel: { color: '#fff' },
            splitLine: { lineStyle: { type: 'dashed', color: '#ccc' } },
          },
        ],
        series: [
          {
            name: '当前负荷',
            type: 'line',
            // stack: '总量',
            data: this.loadForcastingData.seriesData[0].Data,
          },
          {
            name: '预测负荷',
            type: 'line',
            // stack: '总量',
            data: this.loadForcastingData.seriesData[1].Data,
          },
        ],
      };
    },
  },
  watch: {
    selectOrder() {
      this.requestRechargeAnalysis();
    },
  },
  methods: {
    requestRechargeList() {
      getRechargeList({
      }).then((res) => {
        this.rechargeList = res;
        this.selectOrder = res[0].int_SBID;
      });
    },
    requestRechargeAnalysis() {
      if (!this.selectOrder) {
        return;
      }
      getRechargeAnalysis({
        int_SBID: this.selectOrder,
        str_Date: formatDate(new Date(), 'yyyy-MM-dd'),
      }).then((res) => {
        this.rechargeData = res;
      }).catch(() => {

      });
    },
    // 请求可开放容量
    requestOpenCapacity() {
      getOpenCapacity({
        ProjectIDstr: this.selectProject.IdStr,
      }).then((res) => {
        this.openCapacityData = res;
        console.log('requestOpenCapacity', res);
      });
    },
    // 请求变压器损耗
    requestTranLoss() {
      getTransLoss({
        Date: formatDate(new Date(), 'yyyy-MM-dd'),
        ProjectIDstr: this.selectProject.IdStr,
      }).then((res) => {
        this.tranLossData = res;
        console.log(111);
        console.log(this.tranLossData);
        console.log('requestTranLoss', res);
      });
    },
    // 请求负荷预测
    requestLoadForcasting() {
      getLoadForcasting({
        ProjectIDstr: this.selectProject.IdStr,
      }).then((res) => {
        this.loadForcastingData = res;
        console.log('requestLoadForcasting', res);
      });
    },
    getRechargingBoxSize() {
      if (this.$refs.rechargingChart) {
        const dom = this.$refs.rechargingChart.$el;
        // console.log('dom', dom.clientWidth, dom.clientHeight);
        this.rechargingSize.width = dom.clientWidth * 0.94 - 42;
      }
    },
    getSelectOption(order) {
      this.selectOrder = order;
    },
  },
};
</script>
<style lang="less" scoped>
.row-container + .row-container {
  margin-top: 12px;
}
.recharging-state {
  left: 3%;
  bottom: 0;
}
/deep/.el-table--striped .el-table__body tr.el-table__row--striped td{
  color:#fff;
}
/deep/.el-table.dark th, .el-table.dark thead.is-group th{
  background-color: transparent;
   color:#8696BF;
}
/deep/.el-table.dark tr, .el-table.dark, .el-table tr.dark {
 background: transparent;
}
/deep/.el-table--striped:not(.el-table--border) .el-table__body tr.el-table__row--striped.dark td {
  background-color: transparent;
}
// /deep/.el-table tbody tr:hover>td {
//     background-color:#151A59!important
// }
// .el-table.dark tr, .el-table.dark, .el-table tr.dark td{
//   background-color: #151A59;
// }
/deep/.el-table__row td{
background-color: #151A59;
}
</style>
