<template>
  <div
    class="
      recharging-state-item
      d-flex
      align-items-center
      justify-content-center
      position-relative
    "
    :style="{width:`${size.width/2}px`,height:`${size.height}px`}"
  >
    <div class="position-absolute bg"  :style="{ backgroundColor: stateInfo.bgColor }"></div>
    <img
      class="car-image"
      v-if="stateInfo.canRecharg"
      :src="stateInfo.carImg"
    />
  </div>
</template>
<script>
import YellowCarPng from '@/assets/images/project/statistic-analysis/yellow-car.png';

export default {
  name: 'recharging-state-item',
  props: {
    size: {
      type: Object,
      default() {
        return {
          width: 0,
          height: 0,
        };
      },
    },
    stateInfo: {
      type: Object,
      default() {
        return {
          bgColor: '#FFFF01',
          canRecharg: false,
          carImg: YellowCarPng,
        };
      },
    },
  },
};
</script>
<style scoped>
.bg{
  left:0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.2;
}
.car-image {
  width: 1.5rem;
  height:auto;
  opacity: 5.0;
}
</style>
